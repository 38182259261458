import { itemsAsDefaultCitationText, itemsAsRefs, CitationMeta, encodeCitationMeta, decodeCitationMeta } from './citation'
import { GetScQueryItems, genBiblioAndCitations, BiblioResultFailure, BiblioResultSuccess, Style, BiblioMeta, decodeBiblioMeta, encodeBiblioMeta, GetTypeSchema, GetCustomFields } from './bibliography'
import { getCitationControlsFromSelectionOldApi, isSelectionParentBibliographyOldApi } from './wordActionsLegacy'
import { run, SC_BIB_TITLE, SC_CIT_TITLE, isDesktopRuntime } from './wordUtils'
import { chooseIdentApproach, transformNumericIdentsToHyperlinks, transformRcIdentsToHyperlinks } from './identRefsInCitations'
import { v4 as uuidv4 } from 'uuid';

export interface LegacyConvertPayload {
  citationsFound: boolean,
  allConverted?: boolean,
  cannotConvertInWordOnline?: boolean,
}

export interface ReformatPayload {
  canceledByUser?: boolean,
  numCslErrCits?: number
}

let API_1_3_SUPPORTED = false
let API_1_4_SUPPORTED = false
let API_1_6_SUPPORTED = false

export const checkApiCompatibility = () => {
  API_1_3_SUPPORTED = Office.context.requirements.isSetSupported('WordApi', '1.3')
  API_1_4_SUPPORTED = Office.context.requirements.isSetSupported('WordApi', '1.4')
  API_1_6_SUPPORTED = Office.context.requirements.isSetSupported('WordApi', '1.6')
}

export const getBiblioMeta = async (): Promise<BiblioMeta> => {

  const newScStyle = await run(async ctx => {

    const biblioControls =
      ctx
        .document
        .contentControls
        .getByTitle(SC_BIB_TITLE)

    if (!biblioControls)
      return

    biblioControls.load('item')
    await ctx.sync()

    if (!biblioControls.items || !biblioControls.items.length || !biblioControls.items[0])
      return

    const first = biblioControls.items[0]
    first.load('tag')
    await ctx.sync()

    return first.tag
  })

  if (newScStyle)
    return decodeBiblioMeta(newScStyle)

  const { settings } = Office.context.document
  const legacyStyle = settings.get('style')

  if (legacyStyle && legacyStyle.title)
    return { styleTitle: legacyStyle.title }
}

export const removeFirstCitationFromSelection = () =>
  run(async ctx => {
    const scContentControl = await getCitationControlsFromSelection(ctx)
    if (!scContentControl)
      return

    await scContentControl.delete(false)
    await ctx.sync()
  })

export const isSelectionParentBibliography = async () => {
  if (!API_1_3_SUPPORTED)
    return isSelectionParentBibliographyOldApi()

  let selectionParentIsBibliography = false

  selectionParentIsBibliography =
    await run(async ctx => {
      const range =
        ctx
          .document
          .getSelection()

      if (!range)
        return

      range.parentContentControlOrNullObject.load("isNullObject")
      await ctx.sync()

      if (range.parentContentControlOrNullObject.isNullObject)
        return

      range.parentContentControl.load('title')
      await ctx.sync()

      const { title } = range.parentContentControl
      return title === SC_BIB_TITLE
    })

  return selectionParentIsBibliography
}

export const removeAllControls = () =>
  run(async ctx => {

    const citationControls =
      ctx
        .document
        .contentControls
        .getByTitle(SC_CIT_TITLE)

    citationControls.load('item')

    await ctx.sync()

    for (const citation of citationControls.items)
      citation.delete(true)

    await ctx.sync()

    const biblioControls =
      ctx
        .document
        .contentControls
        .getByTitle(SC_BIB_TITLE)

    biblioControls.load('item')

    await ctx.sync()

    for (const biblio of biblioControls.items)
      biblio.delete(true)

    await ctx.sync()
  })

const getCitationControlsFromSelection = async (
  ctx: Word.RequestContext
): Promise<Word.ContentControl | undefined> => {

  if (!API_1_3_SUPPORTED)
    return getCitationControlsFromSelectionOldApi(ctx)

  const range = ctx.document.getSelection()
  if (!range)
    return

  let scContentControl: Word.ContentControl

  const innerContentControls =
    range
      .contentControls
      .getByTitle(SC_CIT_TITLE)

  if (!innerContentControls)
    return

  innerContentControls.load('item')

  await ctx.sync()

  const hasFirstInnerContentControl =
    innerContentControls.items
    && innerContentControls.items.length
    && innerContentControls.items[0]

  if (hasFirstInnerContentControl)
    scContentControl = innerContentControls.items[0]

  if (!scContentControl) {

    range.parentContentControlOrNullObject.load("isNullObject")
    await ctx.sync()

    if (!range.parentContentControlOrNullObject.isNullObject) {
      const parentContentControl = range.parentContentControl

      if (parentContentControl) {
        parentContentControl.load('id, title, tag')

        await ctx.sync()

        if (parentContentControl?.title === SC_CIT_TITLE)
          scContentControl = parentContentControl
      }
    }
  }

  if (!scContentControl)
    return

  scContentControl.load('id, title, tag, font/*')
  await ctx.sync()

  return scContentControl
}

// used only for debugging legacy SC docs
export const debugSelectedLegacyRef = () => {
  const { settings } = Office.context.document
  return run(async ctx => {
    const range = ctx.document.getSelection()
    if (!range)
      return

    let legacyContentControl: Word.ContentControl

    const innerContentControls =
      range
        .contentControls
        .getByTag('citation')

    if (!innerContentControls)
      return

    innerContentControls.load('item')

    await ctx.sync()

    const hasFirstInnerContentControl =
      innerContentControls.items
      && innerContentControls.items.length
      && innerContentControls.items[0]

    if (hasFirstInnerContentControl)
      legacyContentControl = innerContentControls.items[0]

    if (!legacyContentControl) {
      let parentContentControl: Word.ContentControl | undefined
      try {
        parentContentControl = range.parentContentControl
      } catch (e) { }

      if (parentContentControl) {
        parentContentControl.load('id, title, tag')

        await ctx.sync()

        if (parentContentControl?.tag === 'citation')
          legacyContentControl = parentContentControl
      }
    }

    if (!legacyContentControl)
      return

    legacyContentControl.load('id, title, tag')
    await ctx.sync()

    const setting = settings.get(legacyContentControl.id + '')

    // only used for debugging
    console.log('legacy:')
    console.log(console.log(setting.length))
    console.log(setting.map(r => r.collection_id + ':' + r.id).join(',\n'))
  })
}

export const getCitationMetaFromSelection = (): Promise<CitationMeta> =>
  run(async ctx => {
    const scContentControl = await getCitationControlsFromSelection(ctx)
    if (!scContentControl)
      return

    scContentControl.load('tag')
    await ctx.sync()

    const citationMeta =
      scContentControl
      && decodeCitationMeta(scContentControl.tag)

    return citationMeta
  })

export const insertCitation = async (
  ctx: Word.RequestContext,
  content: string,
  meta: CitationMeta,
) => {

  const { document: doc } = ctx
  const range = doc.getSelection()

  if (!range)
    return

  range.load('font/*')
  await ctx.sync()

  const contentControl = range.insertContentControl()
  const html = meta?.options?.manual_text_override || content
  const insertedHtml = contentControl.insertHtml(html, 'Start')
  contentControl.title = 'SmartCite Citation'
  contentControl.tag = encodeCitationMeta(meta)

  const fontProperties = getFontProperties(range)
  insertedHtml.font.set(fontProperties)

  await ctx.sync()
  return contentControl
}

export const replaceFirstCitationInSelection =
  async (
    ctx: Word.RequestContext,
    content: string,
    meta: CitationMeta,
  ) => {
    const scContentControl = await getCitationControlsFromSelection(ctx)
    if (!scContentControl)
      return

    const html = meta?.options?.manual_text_override || content
    const insertedHtml = scContentControl.insertHtml(html, 'Replace')
    const encodedCitationMeta = encodeCitationMeta(meta)

    scContentControl.tag = encodedCitationMeta
    const fontProperties = getFontProperties(scContentControl)
    insertedHtml.font.set(fontProperties)

    await ctx.sync()
    return scContentControl
  }

export const convertLegacy = async (style: Style, locale: string, language: string, getItems: GetScQueryItems, getMapping: GetTypeSchema, getCustomFields: GetCustomFields) => {
  const legacy = await migrateLegacyControlsToNew()
  if (!legacy.citationsFound)
    return legacy

  await removeLegacyBiblio()
  await reformatCitationsAndGenerateBibliography(getItems, getMapping, getCustomFields, { style, locale, language })
  return legacy
}

export const migrateLegacyControlsToNew = async () => {
  const { settings } = Office.context.document

  return run(async (ctx): Promise<LegacyConvertPayload> => {

    const { contentControls } = ctx.document
    const controls = contentControls.getByTag('citation')
    controls.load('item/id, items/tag, item/title')
    await ctx.sync()

    const { items: controlsItems } = controls

    if (!controlsItems.length)
      return { citationsFound: false }

    if (controlsItems.length && !isDesktopRuntime())
      return { citationsFound: true, cannotConvertInWordOnline: true }

    const legacyControlsItems =
      controlsItems
        .filter(i => i.title !== SC_CIT_TITLE)

    if (!legacyControlsItems.length)
      return { citationsFound: false }

    let citationConvertIssue = false

    for (const control of legacyControlsItems) {
      const range = control && control.getRange()

      if (!range) {
        control.delete(true)
        continue
      }

      const setting = settings.get(control.id + '')

      const validSetting =
        setting
        && setting.length

      if (!validSetting) {
        range.font.color = 'white'
        range.font.highlightColor = 'red'
        citationConvertIssue = true
        control.delete(true)
        continue
      }

      const validLegacyScItems =
        setting
          .filter(entry => entry.id && entry.collection_id)

      if (validLegacyScItems && validLegacyScItems.length) {
        const text = itemsAsDefaultCitationText(validLegacyScItems)
        control.insertHtml(text, 'Replace')
        control.tag = itemsAsRefs(validLegacyScItems).join(',')
        control.title = SC_CIT_TITLE
      } else {
        citationConvertIssue = true
        range.font.color = 'white'
        range.font.highlightColor = 'red'
      }
    }

    await ctx.sync()
    return { citationsFound: true, allConverted: !citationConvertIssue }
  })
}

export const removeLegacyBiblio = async () =>
  run(async ctx => {

    const { contentControls } = ctx.document

    const biblioControls = contentControls.getByTag('bibliography')

    if (!biblioControls)
      return

    biblioControls.load('item')
    await ctx.sync()

    if (!biblioControls.items || !biblioControls.items.length || !biblioControls.items[0])
      return

    biblioControls.items[0].delete(false)

    await ctx.sync()
  })

export const getTrackingMode = async (): Promise<Word.Document["changeTrackingMode"] | "RC_UNSUPPORTED_BY_WORD_API"> => {
  if (!API_1_4_SUPPORTED)
    return "RC_UNSUPPORTED_BY_WORD_API"

  return run (async ctx => {
    ctx.document.load('changeTrackingMode')
    await ctx.sync()
    return ctx.document.changeTrackingMode
  })
}

export const setTrackingMode = async (trackingMode: Word.Document["changeTrackingMode"]) => {
  if (!API_1_4_SUPPORTED)
    return "RC_UNSUPPORTED_BY_WORD_API"

  run (async ctx => {
    ctx.document.load('changeTrackingMode')
    await ctx.sync()
    ctx.document.changeTrackingMode = trackingMode
    await ctx.sync()
  })
}

export const reformatCitationsAndGenerateBibliography =
  async (
    getItems: GetScQueryItems,
    getMapping: GetTypeSchema,
    getCustomFields: GetCustomFields,
    options?: {
      style?: Style,
      locale?: string,
      language?: string,
      citationOp?: (ctx: Word.RequestContext) => Promise<Word.ContentControl>,
      transformToLinks?: boolean,
      sectionsMode?: boolean
    },
  ): Promise<ReformatPayload | void> =>
    run(async ctx => {

      let citationOpContentControl: Word.ContentControl | void
      if (options?.citationOp)
        citationOpContentControl = await options.citationOp(ctx)

      const { sections, citationContentControlGroups } = await getCitationGroupsAndSections(ctx, options?.sectionsMode)

      await deleteExistingBibliosInEmptyGroups(ctx, options?.sectionsMode, sections, citationContentControlGroups)

      const citationMetasGroups: CitationMeta[][] = []

      for (let i = 0; i < citationContentControlGroups.length; i++) {
        citationMetasGroups.push(
          citationContentControlGroups[i]
            .map(ctrl => decodeCitationMeta(ctrl.tag))
        )
      }

      const biblios: (BiblioResultFailure | BiblioResultSuccess)[] = []

      for (let i = 0; i < citationMetasGroups.length; i++) {
        if (!citationMetasGroups[i] || !citationMetasGroups[i].length) {
          biblios.push(null)
          continue
        }

        const biblioResult = await genBiblioAndCitations(citationMetasGroups[i], getItems, getMapping, getCustomFields, options)
        biblios.push(biblioResult)
      }

      for (let j = 0; j < biblios.length; j++) {
        if (!biblios[j])
          continue

        if (!biblios[j].success) {

          const failurePayload = (biblios[j] as BiblioResultFailure).payload

          if (failurePayload.reason === 'error')
            return

          const items = failurePayload.items
          if (!items || !items.length)
            return { canceledByUser: true }

          for (let i = 0; i < citationContentControlGroups[j].length; i++) {

            const citationContentControl = citationContentControlGroups[j][i]

            if (!citationContentControl)
              continue

            if (!citationMetasGroups[j])
              continue

            const refs = citationMetasGroups[j][i]?.refs
            const hasNullItem =
              refs && refs.length && refs
                .map(ref => {
                  const [refCollectionId, refItemId] = ref.split(':')
                  const itemMatch =
                    items
                      .find(item =>
                        item
                        && item.collection_id === refCollectionId
                        && item.id === refItemId
                      )
                  return itemMatch
                })
                .includes(undefined)

            if (hasNullItem)
              citationContentControl.font.highlightColor = 'orange'

            await ctx.sync()
          }

          return { canceledByUser: true }
        }
      }

      let numCslErrCits = 0

      for (let j = 0; j < biblios.length; j++) {
        if (!biblios[j])
          continue

        const biblioPayload = (<BiblioResultSuccess>biblios[j]).payload

        const bookmarks =
          biblioPayload
            ?.biblioRefs
            ?.reduce((refBookMap: Record<string, string>, ref, index) => {

              if (!refBookMap[ref])
                refBookMap[ref] = 'biblioRef' + j + index
                // refBookMap[ref] = 'bibRef' + j + uuidv4().split('-').join('');

              return refBookMap
            }, {})

        if (biblioPayload.citations)
          for (let i = 0; i < citationContentControlGroups[j].length; i++) {

            const citationContentControl = citationContentControlGroups[j][i]

            if (!citationContentControl)
              continue

            // Skip replacing citations that are deleted in tracking
            if (API_1_6_SUPPORTED && isDesktopRuntime()) {
              const currentTracking = await getTrackingMode()

              if (currentTracking === 'TrackAll' || currentTracking === 'TrackMineOnly') {
                const range = citationContentControl.getRange()
                range.load('text')

                const trackedChanges = range.getTrackedChanges()
                ctx.load(trackedChanges, 'items')
                await ctx.sync()

                const controlDeletedInTracking = isControlDeletedInTracking(range, trackedChanges)
  
                if (trackedChanges.items.length > 0 && controlDeletedInTracking) {
                  console.log('Only deleted tracked changes found within the content control, skipping content control reformat.')
                  continue
                }
              }
            }

            const citation = biblioPayload.citations[i]

            if (citation && citation.citationResult) {

              if (options?.transformToLinks) {

                const shouldInjectRcIdents =
                  chooseIdentApproach({ style: options?.style?.body }) === "inject_rc_identifiers_to_citation"

                let htmlToInsert = ''
                if (shouldInjectRcIdents) {
                  htmlToInsert = transformRcIdentsToHyperlinks({ citationHtml: citation.citationResult, bookmarks })
                } else {
                  htmlToInsert =
                    transformNumericIdentsToHyperlinks({
                      citationHtml: citation.citationResult,
                      biblioRefs: biblioPayload.biblioRefs,
                      bookmarks
                    })
                }

                const fontProperties = getFontProperties(citationContentControl)
                fontProperties.underline = 'None'
                const insertedHtml = citationContentControl.insertHtml(htmlToInsert, 'Replace')
                insertedHtml.font.set(fontProperties)

              } else {
                const insertedHtml = citationContentControl.insertHtml(citation.citationResult, 'Replace')
                const fontProperties = getFontProperties(citationContentControl)
                insertedHtml.font.set(fontProperties)
                citationContentControl.tag = encodeCitationMeta(citation.meta)
              }

              if (citation.hasCslError) {
                citationContentControl.font.highlightColor = 'orange'
                numCslErrCits++
              }

            } else {
              citationContentControl.delete(false)
            }

            await ctx.sync()
          }

        const existingBiblioContentControls = getExistingBiblioControls(ctx, options?.sectionsMode, sections, j)

        if (!existingBiblioContentControls)
          continue

        existingBiblioContentControls.load('item, items/tag, items/font/*')

        await ctx.sync()

        // render biblio in the document
        let biblioControl: Word.ContentControl | undefined

        const hasFirst =
          existingBiblioContentControls
          && existingBiblioContentControls.items
          && existingBiblioContentControls.items.length
          && existingBiblioContentControls.items[0]

        const isSectionModeInDoc = hasFirst && !!decodeBiblioMeta(existingBiblioContentControls.items[0]?.tag)?.options?.isSectionsModeOn

        const isSectionModeSwitch = isSectionModeInDoc && !options?.sectionsMode

        // if multiple biblios found (either in section or in whole doc) delete them all
        // also if switching from section mode to non section mode and only one biblio is in document,
        // delete it to move new biblio control to end of document
        if (existingBiblioContentControls.items.length > 1 || isSectionModeSwitch)
          existingBiblioContentControls.items.forEach(i => i.delete(false))

        else if (hasFirst)
          biblioControl = existingBiblioContentControls.items[0]

        await ctx.sync()

        let lastParagraphFontName

        if (!biblioControl) {

          // workaround for word online (when we create a content control word online has all font
          // properties set but name)

          const paragraphs =
            ctx
              .document
              .body
              .paragraphs
              .load('item')

          await ctx.sync()

          const lastParagraph =
            paragraphs.items.length
            && paragraphs.items[paragraphs.items.length - 1]

          if (lastParagraph) {
            lastParagraph.load('font/name')
            await ctx.sync()
            if (lastParagraph.font.name)
              lastParagraphFontName = lastParagraph.font.name
          }

          const normalCreateBiblioControl = () =>
            ctx
              .document
              .body
              .getRange('End')
              .insertContentControl()

          // extra paragraph is needed in the sections mode because otherwise section break 
          // becomes part of the biblio cc and gets deleted together with cc when sections mode is turned off
          const fallbackCreateBiblioControl = () => {
            if (options?.sectionsMode) {
              const paragraph =
                sections
                  .items[j]
                  .body
                  .insertParagraph(' ', 'End')
              return paragraph
                .insertParagraph(' ', 'Before')
                .insertContentControl()
            } else {
              return ctx
                .document
                .body
                .insertParagraph(' ', 'End')
                .insertContentControl()
            }
          }

          biblioControl =
            API_1_3_SUPPORTED && isDesktopRuntime() && !options?.sectionsMode
              ? normalCreateBiblioControl()
              : fallbackCreateBiblioControl()

          biblioControl.title = SC_BIB_TITLE

          biblioControl.tag = encodeBiblioMeta({ styleTitle: options?.style?.title, options: { language: options?.language, isSectionsModeOn: !!options?.sectionsMode } })
          biblioControl.load('font/*')

          await ctx.sync()

          // if not in multi biblio mode, create empty paragraph at the end of document (after the bibliography) to
          // prevent issues with tracking mode and fix https://readcube.atlassian.net/browse/RSCWORD-413
          // when switching from sections mode to single biblio mode, we delete all bibliographies in the doc, creating a new one at the end
          // of the document. That creation fails in tracking mode if the end of the document has deleted content in tracking (usually the last section
          // bibliography that was deleted in previous step)
          if (!options?.sectionsMode) {
            ctx
              .document
              .body
              .insertParagraph(' ', 'End')
  
            await ctx.sync()
          }
        }

        if (biblioPayload.biblioHtml) {

          if (options?.transformToLinks) {

            biblioControl.insertHtml('<br/>', 'Replace')
            let biblioBodyHtml = ''

            for (const [snippetIndex, biblioHtmlSnippet] of biblioPayload.biblioHtml.entries()) {

              const biblioRef = biblioPayload.biblioRefs[snippetIndex]
              const biblioBookmark = bookmarks[biblioRef]

              const snippetDom =
                new DOMParser()
                  .parseFromString(biblioHtmlSnippet, 'text/html')

              const innerElem = snippetDom.querySelector('p') || snippetDom.querySelector('div')
              const pElemInnerHtml = innerElem?.innerHTML
              if (innerElem)
                innerElem.innerHTML = `<a name="${biblioBookmark}">${pElemInnerHtml}</a>`
              const htmlToInsert = snippetDom.body.innerHTML

              biblioBodyHtml += htmlToInsert
            }

            const insertedHtml = biblioControl.insertHtml('<br/>' + biblioBodyHtml + '<span>&nbsp;</span>', 'End')
            const fontProperties = getFontProperties(biblioControl, { fontNameDefault: lastParagraphFontName })
            insertedHtml.font.set(fontProperties)

            biblioControl.tag = encodeBiblioMeta({ styleTitle: options?.style?.title, options: { language: options?.language, isSectionsModeOn: !!options?.sectionsMode } })
          } else {

            const fullBiblioHtml = '<br/>' + biblioPayload.biblioHtml.join('') + '<span>&nbsp;</span>'
            const insertedHtml = biblioControl.insertHtml(fullBiblioHtml, 'Replace')
            biblioControl.tag = encodeBiblioMeta({ styleTitle: options?.style?.title, options: { language: options?.language, isSectionsModeOn: !!options?.sectionsMode } })
            const fontProperties = getFontProperties(biblioControl, { fontNameDefault: lastParagraphFontName })
            insertedHtml.font.set(fontProperties)

          }

        } else if (biblioPayload.citations?.length) {
          biblioControl.tag = encodeBiblioMeta({ styleTitle: options?.style?.title, options: { language: options?.language, isSectionsModeOn: !!options?.sectionsMode } })
          clearBiblioContent(biblioControl)
        } else {
          biblioControl.delete(false)
        }

        await ctx.sync()
      }

      if (citationOpContentControl)
        citationOpContentControl.select()

      await ctx.sync()

      return { numCslErrCits }
    })

export const getDocumentCitationRefs = () =>
  run(async ctx => {

    const controls =
      ctx
        .document
        .contentControls
        .getByTitle(SC_CIT_TITLE)

    if (!controls)
      return

    controls.load('item, items/tag')
    await ctx.sync()

    if (!controls || !controls.items || !controls.items.length)
      return

    const citationsRefs =
      controls
        .items
        .map(i => decodeCitationMeta(i.tag).refs)
        .reduce((a, v) => a.concat(v), [])

    return [... new Set(citationsRefs)]
  })

const getFontProperties = (
  source: Word.ContentControl | Word.Range,
  opts?: { fontNameDefault?: string },
) => {

  const fontProperties: any = {
    name: source.font.name || opts?.fontNameDefault
  }

  if (API_1_3_SUPPORTED)
    fontProperties.highlightColor = source.font.highlightColor

  // word online sometimes gives us font sizes that cannot be applied (Word will error if we
  // try to set the font size value that is read from Word online)
  const isValidFontSize =
    source.font.size
    && !isNaN(source.font.size)
    && source.font.size > 0

  if (isValidFontSize)
    fontProperties.size = source.font.size

  Object
    .entries(fontProperties)
    .forEach(([k, v]) => {
      if (v == null || v === '')
        delete fontProperties[k]
    })

  return fontProperties
}

export const performOpAndSelect = (
  citationOp?: (ctx: Word.RequestContext) => Promise<Word.ContentControl>
) =>
  run(async ctx => {
    const control = await citationOp(ctx)
    if (control)
      control.select()
    await ctx.sync()
  })

const clearBiblioContent = (biblioControl: Word.ContentControl) => {
  if (isDesktopRuntime()) {
    biblioControl.insertHtml('<span></span>', 'Replace')
    biblioControl.placeholderText = ''
  }
  else
    biblioControl.insertHtml('<br/>', 'Replace')
}

const getCitationGroupsAndSections = async (
  ctx: Word.RequestContext,
  sectionsMode: boolean
): Promise<{ sections: Word.SectionCollection | null, citationContentControlGroups: Word.ContentControl[][] }> => {

  let sections: Word.SectionCollection | null
  let citationContentControlGroups: Word.ContentControl[][] = []

  if (sectionsMode) {
    sections =
      ctx
        .document
        .sections

    sections.load('item')
    await ctx.sync()

    for (let i = 0; i < sections.items.length; i++) {
      const sectionCitationContentControls =
        sections
          .items[i]
          .body
          .contentControls
          .getByTitle(SC_CIT_TITLE)

      sectionCitationContentControls.load('item, items/tag, font/*')
      await ctx.sync()

      const filteredContentControls: Word.ContentControl[] = await getTrackingFilteredContentControls(ctx, sectionCitationContentControls);

      citationContentControlGroups.push(filteredContentControls)
    }

    return { sections, citationContentControlGroups }

  } else {
    const citationContentControls =
      ctx
        .document
        .contentControls
        .getByTitle(SC_CIT_TITLE)

    citationContentControls.load('item, items/tag, font/*')
    await ctx.sync()

    const filteredContentControls: Word.ContentControl[] = await getTrackingFilteredContentControls(ctx, citationContentControls);

    citationContentControlGroups = [filteredContentControls]

    return { sections: null, citationContentControlGroups }
  }
}

const deleteExistingBibliosInEmptyGroups = async (
  ctx: Word.RequestContext,
  sectionsMode: boolean,
  sections: Word.SectionCollection,
  citationContentControlGroups: Word.ContentControl[][]): Promise<void> => {

  for (let i = 0; i < citationContentControlGroups.length; i++) {
    const noCitationsInGroup =
      !citationContentControlGroups[i]
      || !citationContentControlGroups[i].length

    if (noCitationsInGroup) {
      const existingBiblio = getExistingBiblioControls(ctx, sectionsMode, sections, i)

      await existingBiblio.load('item')

      await ctx.sync()

      const noExistingBiblio =
        !existingBiblio
        || !existingBiblio.items
        || !existingBiblio.items.length

      if (noExistingBiblio)
        continue

      existingBiblio
        .items
        .forEach(i => i.delete(false))

      await ctx.sync()
    }
  }
}

const getExistingBiblioControls = (
  ctx: Word.RequestContext,
  sectionsMode?: boolean,
  sections?: Word.SectionCollection,
  sectionIndex?: number) => {

  if (!sectionsMode)
    return ctx.document
      .contentControls
      .getByTitle(SC_BIB_TITLE)

  return sections
    .items[sectionIndex]
    .body
    .contentControls
    .getByTitle(SC_BIB_TITLE)
}

const getTrackingFilteredContentControls = async (ctx: Word.RequestContext, citationContentControls: Word.ContentControlCollection) => {
  const controls: Word.ContentControl[] = [];  
  const filteredControls: Word.ContentControl[] = [];  

  for (let i = 0; i < citationContentControls.items.length; i++) {
    controls.push(citationContentControls.items[i])
  }

  if (!API_1_6_SUPPORTED || !isDesktopRuntime())
    return controls

  const currentTracking = await getTrackingMode()
  if (currentTracking !== 'TrackAll' && currentTracking !== 'TrackMineOnly')
    return controls

  for (let i = 0; i < controls.length; i++) {
    const range = controls[i].getRange();
    range.load('text')

    const trackedChanges = range.getTrackedChanges();
    ctx.load(trackedChanges, 'items')

    await ctx.sync()

    const controlDeletedInTracking = isControlDeletedInTracking(range, trackedChanges)

    if (trackedChanges.items.length === 0 || !controlDeletedInTracking)
      filteredControls.push(citationContentControls.items[i])
  }

  return filteredControls
}

const isControlDeletedInTracking = (range: Word.Range, changes: Word.TrackedChangeCollection) => {
  // comparing original and deleted text we detect if the whole content control was deleted
  const originalText = range.text;
  const deletedText = changes.items
    .filter(change => change.type === Word.TrackedChangeType.deleted)
    .map(change => change.text)
    .join('')

    return originalText === deletedText
}
