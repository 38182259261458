
// native
import { Injectable } from '@angular/core'
import { AppConfig } from '../models/models'
import { environment } from 'src/environments/environment'
import { BUILD_VERSION, BUILD_TIME } from '../../../build/build-version'

import { DocumentService } from '@readcube/smartcite-shared'
import { ICitationOptions } from '@readcube/smartcite-shared/lib/models'

import { itemsAsDefaultCitationText, itemsAsRefs, CitationMeta } from './citing/citation'
import { BiblioMeta, Style } from './citing/bibliography'
import { convertEndnote } from './citing/endnote'
import { convertQuosa } from './citing/quosa'
import * as word from './citing/wordActions'

import { TrackJS } from 'trackjs'

const BIBLIO_SELECTION_ERROR = 'biblio-selection'

export let API_1_3_SUPPORTED = false

interface DocumentCitations {
  ids: string[]
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private documentService: DocumentService
  ) {
    word.checkApiCompatibility()

    OfficeExtension.config.extendedErrorLogging = true
    Office.context.document.addHandlerAsync(
      Office.EventType.DocumentSelectionChanged,
      this.onDocumentSelectionChanged,
    )
  }

  onDocumentSelectionChanged = async () => {
    TrackJS.console.info('Document selection changed')
    console.log('Document selection changed')
    const meta = await word.getCitationMetaFromSelection()

    if (!meta) {
      this.documentService.onCitationSelect(null)
      return
    }

    this.documentService.onCitationSelect({
      ids: meta.refs,
      options: meta.options,
    })
  };

  addCitation = async (
    items,
    style,
    locale,
    language,
    isAutoUpdate,
    citationOptions: ICitationOptions,
    transformToLinks: boolean,
    sectionsMode: boolean
  ): Promise<word.ReformatPayload | void> => {

    TrackJS.console.info('Adding citation')

    const hasItems =
      items && items.length

    if (!hasItems)
      return

    const citationText = itemsAsDefaultCitationText(items)
    const citationRefs = itemsAsRefs(items)
    const citationMeta: CitationMeta = {
      refs: citationRefs,
      options: citationOptions,
    }

    const isParentBibliography = await word.isSelectionParentBibliography()
    if (isParentBibliography)
      throw BIBLIO_SELECTION_ERROR

    const citationOp = ctx =>
      word.insertCitation(ctx, citationText, citationMeta)

    if (!isAutoUpdate)
      return word.performOpAndSelect(citationOp)

    return word.reformatCitationsAndGenerateBibliography(
      (r: any) => this.documentService.getBibliographyItems(r),
      (id: any) => this.documentService.getTypeSchema(id),
      (id: any) => this.documentService.getCustomFields(id),
      { style, locale, language, citationOp, transformToLinks, sectionsMode }
    )
  };

  updateCitation = async (
    items,
    style,
    locale,
    language,
    isAutoUpdate,
    citationOptions: ICitationOptions,
    transformToLinks: boolean,
    sectionsMode: boolean
  ): Promise<word.ReformatPayload | void> => {

    TrackJS.console.info('Update citation')

    const hasItems =
      items && items.length

    if (!hasItems) {
      await word.removeFirstCitationFromSelection()

      if (isAutoUpdate)
        await word.reformatCitationsAndGenerateBibliography(
          (r: any) => this.documentService.getBibliographyItems(r),
          (id: any) => this.documentService.getTypeSchema(id),
          (id: any) => this.documentService.getCustomFields(id),
          { style, locale, language, transformToLinks, sectionsMode }
        )

      return
    }

    const citationText = itemsAsDefaultCitationText(items)
    const citationRefs = itemsAsRefs(items)
    const citationMeta: CitationMeta = {
      refs: citationRefs,
      options: citationOptions,
    }

    const citationOp = ctx =>
      word.replaceFirstCitationInSelection(ctx, citationText, citationMeta)

    if (!isAutoUpdate)
      return word.performOpAndSelect(citationOp)

    return word.reformatCitationsAndGenerateBibliography(
      (r: any) => this.documentService.getBibliographyItems(r),
      (id: any) => this.documentService.getTypeSchema(id),
      (id: any) => this.documentService.getCustomFields(id),
      { style, locale, language, citationOp, transformToLinks, sectionsMode }
    )
  };

  updateBibliography = (style, locale, language, transformToLinks: boolean, sectionsMode: boolean): Promise<word.ReformatPayload | void> => {
    TrackJS.console.info('Updating bibliography')

    return word.reformatCitationsAndGenerateBibliography(
      (r: any) => this.documentService.getBibliographyItems(r),
      (id: any) => this.documentService.getTypeSchema(id),
      (id: any) => this.documentService.getCustomFields(id),
      { style, locale, language, transformToLinks, sectionsMode }
    )
  };

  getCitations = async (): Promise<DocumentCitations> => {
    TrackJS.console.info('Fetching citations')
    const ids = (await word.getDocumentCitationRefs())
    return { ids: [...new Set(ids)] }
  };

  convertLegacy = (style: Style, locale: string, language: string) => {
    TrackJS.console.info('Converting legacy')
    return word.convertLegacy(style, locale, language,
      (r: any) => this.documentService.getBibliographyItems(r),
      (id: any) => this.documentService.getTypeSchema(id),
      (id: any) => this.documentService.getCustomFields(id))
  };

  convertToPlainText = () => {
    TrackJS.console.info('Converting to plain text')
    return word.removeAllControls()
  };

  convertFromEndnote = async (style: Style, locale: string, language: string, transformToLinks: boolean) => {
    TrackJS.console.info('Converting endnote')

    const currentTracking = await word.getTrackingMode()
    let trackingToRestore: Word.Document["changeTrackingMode"] | null = null
    if (currentTracking === 'TrackAll' || currentTracking === 'TrackMineOnly') {
      trackingToRestore = currentTracking
      await word.setTrackingMode(Word.ChangeTrackingMode.off)
    }

    const conversionResult = await convertEndnote(
      (items: any) => this.documentService.getItemsFromDocumentItems(items)
    )

    if (trackingToRestore)
      await word.setTrackingMode(trackingToRestore)

    if (!conversionResult?.isConverted)
      return conversionResult

    await word.reformatCitationsAndGenerateBibliography(
      (r: any) => this.documentService.getBibliographyItems(r),
      (id: any) => this.documentService.getTypeSchema(id),
      (id: any) => this.documentService.getCustomFields(id),
      { style, locale, language, transformToLinks },
    )

    return conversionResult
  };

  convertFromQuosa = async (style: Style, locale: string, language: string, transformToLinks: boolean) => {
    TrackJS.console.info('Converting from quosa')
    const conversionResult = await convertQuosa(
      (items: any) => this.documentService.getItemsFromDocumentItems(items)
    )

    if (!conversionResult?.isConverted)
      return conversionResult

    await word.reformatCitationsAndGenerateBibliography(
      (r: any) => this.documentService.getBibliographyItems(r),
      (id: any) => this.documentService.getTypeSchema(id),
      (id: any) => this.documentService.getCustomFields(id),
      { style, locale, language, transformToLinks },
    )

    return conversionResult
  };

  getBiblioMeta = (): Promise<BiblioMeta> => {
    TrackJS.console.info('Fetching biblio meta')
    return word.getBiblioMeta()
  };

  // tslint:disable-next-line:member-ordering
  appConfig: AppConfig = {
    environment,
    addCitation: this.addCitation,
    updateCitation: this.updateCitation,
    updateBibliography: this.updateBibliography,
    clearCitationQueueOnAddUpdate: true,
    getCitations: this.getCitations,
    convertLegacy: this.convertLegacy,
    convertToPlainText: this.convertToPlainText,
    convertFromEndnote: this.convertFromEndnote,
    convertFromQuosa: this.convertFromQuosa,
    openLogin: this.openLogin,
    openTutorial: this.openTutorial,
    getBiblioMeta: this.getBiblioMeta,
    defaultAutoUpdate: true,
    buildVersion: BUILD_VERSION,
    buildTime: BUILD_TIME
  };

  openLogin(): Promise<string> {
    const loginUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/login.html'

    return new Promise((resolve, reject) => {
      Office.context.ui.displayDialogAsync(loginUrl, { width: 50, height: 70 }, asyncResult => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          reject()
        }

        const dialog: Office.Dialog = asyncResult.value
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, dialogResult => {

          if ((<{error: number}>dialogResult).error) {
            dialog.close()
            reject()
          }

          const data = JSON.parse((<{message: string, origin: string}>dialogResult).message)
          if (data.outcome === 'success' && data.authToken)
            resolve(data.authToken)

          dialog.close()
        })

        dialog.addEventHandler(Office.EventType.DialogEventReceived, () => reject())
      })
    })
  };

  openTutorial(): Promise<void> {
    return new Promise((resolve, reject) => {
      const tutorialUrl: string =
        location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/tutorial.html'
      Office.context.ui.displayDialogAsync(tutorialUrl, { width: 68, height: 72 })
      resolve()
    })
  };
}

